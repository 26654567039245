import React from 'react';
import { Parallax } from 'react-parallax'; 
import styles from './Parallax.css.js';
import FadeIn from 'react-fade-in';
 
const MyComponent = () => (
    <div>
        {/* -----basic config-----*/}
        <FadeIn delay='500' transitionDuration='1500'>
        <Parallax
            //blur={10}
            bgImage={require('../../../resources/primary.jpg')}
            bgImageAlt="splash"
            strength={50}
        >

            <div style={styles.parallaxContainer}> 
                <div style={styles.parallaxOpacity}>
                <FadeIn delay='1200' transitionDuration='1500' >
                    <h1 style={styles.parallaxCopy2}>Discerning</h1>
                    <h1 style={styles.parallaxCopy1}>Honest</h1>
                    <h1 style={styles.parallaxCopy3}>Responsive</h1>
                    <h1 style={styles.parallaxCopy4} >Professional</h1>
                </FadeIn>
                </div>
            </div>
        </Parallax>
        </FadeIn>
 
    </div>
);
export default MyComponent;