import React from 'react'
import styles from './Intro.css.js'

export default function Intro() {
    return (
        <div className="Intro" style={styles.introContainer}>
            <center><h2 className="head" style={styles.head}>Welcome to Sparks Psych Services!</h2></center>
            <p className="body" style={styles.body}>We are a team of psychologists who specialize in evaluations and other
services including testing, training, therapy, and testimony. Our doctors are
experienced, honest, and discerning and  we use the right specialist for each case.
There is no one-size-fits-all here. We listen carefully to what you need and find the
expert who can best answer your specific questions.</p>
            <div id='forensic'></div>
            <p className="call" style={styles.call} >So, how can we help you?</p>
        </div>
    )
}
