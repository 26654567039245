export default {
    headerContainer: {
        height: '140px',
        background: 'linear-gradient(180deg, #a1ccdd, #fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 8px 0 rgba(0, 0, 0, 0.19)',
        margin: '0 0 -.75em 0',
      
        
        

    },
    logo: {
        maxWidth: '250px',
       
    }
}