
import splashImage from '../../../resources/congress.jpg'

export default {
    splashContainer:{
        display: 'flex',
        width: '100%',
        margin: '1em 0',
        //boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    },
    splashCall:{
        width: '40%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#424242',
        color: 'white',
        padding: '1em',
    },
    primaryCall:{
        color: '#E6B277',
        fontSize: '45px',
        textTransform: 'uppercase',
        textAlign: 'center',
        padding: '1em 3rem 0 3rem',    
    },
    primaryCall2:{
        color: '#E6B277',
        fontSize: '28px',
        textTransform: 'uppercase',
        textAlign: 'center',
        padding: '0 3rem',    
    },
    primaryCall3:{
        color: '#E6B277',
        fontSize: '29px',
        textTransform: 'uppercase',
        textAlign: 'center',
        padding: '0 3rem',    
    },
    primaryCall4:{
        color: '#E6B277',
        fontSize: '24px',
        textTransform: 'uppercase',
        textAlign: 'center',
        padding: '0 3rem',    
    },
    underline:{
        width: '100px',
        margin: '1.5em 0 5em 0',
        height: '1px',
        backgroundColor: '#E6B277',
    
    },
    secondaryCall:{
        padding: '1.5em',
        fontSize: '20px',
    },
    phone: {
        color: '#143257',
        textDecoration: 'none',
        backgroundColor: '#fff8e5',
        padding: '.5em',
        width: '100%',
        fontSize: '1.25em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'

    },
    splashImage: {
        width: "100%",
        height: "80%",
        backgroundImage: `url(${splashImage})`,
        backgroundSize: 'cover',
        backgroundPosition: ' center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: '0',
        left: '0',
        zIndex: '-1'
    }

}