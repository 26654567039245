export default {
    teamContainer: {
        margin: '1.5em', 
        height: '315px',
        backgroundColor: '#fff8e5',
        display:'flex',
        padding: '1em',
        flexDirection: 'column',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        
    },
    team:{
        display: 'flex',
        justifyContent: 'center',
        

    },
    head:{
        fontSize: '1.6em',
        margin: '1em',
        textTransform: 'uppercase',
        color: '#143257',
        
    },
    memberContainer: {
        display: 'flex',
        margin: "0 0 0 1em"
        //overflowX: 'scroll',
        //overflowY: 'hidden',
    },
    member: {
        maxHeight: '290px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0 3em',
        justifyContent: 'center',
        cursor: 'pointer',
        
    },
    memberImg: {
        height: '170px',
        width: '170px',
        borderRadius: '50%',
        
    },
    name:{
        textTransform: 'uppercase',
        color: '#143257',
        fontWeight: '600',
        margin: '.5em',
        fontSize: '.75em',
        textAlign: 'center',
        
    },
    title: {
        color: '#424242',
        fontSize: '.85em',
        
    },
    bioContainer:{
        display: 'flex',
        flexDirection: 'column',
        color: '#143257',
        
    },
    bioContainerClick:{
        display: 'flex',
        flexDirection: 'column',
        color: '#143257',
        
    },
    bioHead:{
        padding: '.5em 0',
        fontSize: '1em',
        textTransform: 'uppercase',
        fontWeight: '600',
        
    },
    bioCopy:{
        width: '240px',
        textAlign: 'justify',
        overflow: 'scroll',
        height: '180px',
        fontSize: '12.5px',
        
    },
    carousel:{
        display: 'flex',
        
    },
    teamSliderDiv: {
        width: '90%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        
        //overflow: 'visible',
    },
    slider: {
        display: 'flex',
        width: '97%',
        alignItems: 'center',
          },
    teamMember: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        overflow: 'visible',
        },
    h1:{
        fontFamily: 'Bellota',
        margin: '.75em 0 .25em 0',
        backgroundColor: '#f2f2f2',
    },
    h3 : {
        margin: '.25em 0',
        fontWeight: '300',
    },
    whole: {
        display: 'flex',
        
    },
    


}