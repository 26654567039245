import React from 'react';
import Media from'react-media';
import TestingDesktop from './TestingDesktop.js';
import TestingMobile from './TestingMobile.js'

export default function PsychologicalTesting() {
    return (
        <div >
             <Media queries={{ small: { maxWidth: 770 } }}>
                     {matches =>
                         matches.small ? (
                             <TestingMobile />
                         ) : (
                             <TestingDesktop />
                         )
                     }
                     </Media>   
        </div>
    )
}
