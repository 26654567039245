import React from 'react'; 
import Media from'react-media';
import TherapyDesktop from './TherapyDesktop.js';
import TherapyMobile from './TherapyMobile.js'

export default function Psychotherapy() {
    return (
        <div >
        <Media queries={{ small: { maxWidth: 770 } }}>
                {matches =>
                    matches.small ? (
                        <TherapyMobile />
                    ) : (
                        <TherapyDesktop />
                    )
                }
                </Media>   
   </div>
    )
}
