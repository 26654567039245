import React from 'react'; 
import Media from'react-media';
import ForensicDesktop from './ForensicDesktop.js'; 
import ForensicMobile from './ForensicMobile.js'

export default function ForensicEvaluations() {

    return (
        <div >
             <Media queries={{ small: { maxWidth: 770 } }}>
                     {matches =>
                         matches.small ? (
                             <ForensicMobile />
                         ) : (
                             <ForensicDesktop />
                         )
                     }
                     </Media>   
        </div>
    )
}
