import React from 'react'
import styles from './Splash.css.js';
import MyComponent from '../../../components/Home/Parallax/Parallax.js'




export default function Splash() { 

    return (
        <div className='splash' style={styles.splashContainer}>
            {/*<div className='splashCall' style={styles.splashCall}>
                <h1 style={styles.primaryCall}>honest </h1>
                <h1 style={styles.primaryCall2}>descerning</h1>
                <h1 style={styles.primaryCall3}>responsive</h1>
                <h1 style={styles.primaryCall4}>professional</h1>
                <div className='underline' style={styles.underline}></div> 
                
                <h2 style={styles.secondaryCall}>How can we help you today?</h2>
                <a href='tel:513-297-3455' style={styles.phone} id='forensic'>513.297.3455</a>
                
            </div> 
               
            <div className='splashImage' style={styles.splashImage}>
            
            </div>*/}
            <MyComponent />

            
        </div>
    )
}
