import React from 'react';
import Media from'react-media';
import TrainingsDesktop from './TrainingsDesktop.js';
import TrainingsMobile from './TrainingsMobile.js'

export default function Trainings() {
    return (
        <div >
             <Media queries={{ small: { maxWidth: 770 } }}>
                     {matches =>
                         matches.small ? (
                             <TrainingsMobile />
                         ) : (
                             <TrainingsDesktop />
                         )
                     }
                     </Media>   
        </div>
    )
}
