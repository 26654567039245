import React from 'react';
import styles from './Trainings.css.js';
import FadeIn from 'react-fade-in';
import { Parallax } from 'react-parallax';

export default function TrainingsMobile() {
    return (
        <div className='trainingsContainer' style={styles.trainingsContainerMobile}>
             <div className='trainingsCopy' style={styles.trainingsCopyMobile}>
             <FadeIn delay='2000' transitionDuration='2000' >
                <h1 style={styles.head}>Training</h1>
                <ul style={styles.itemContainer}>
                    <div>
                    <center><li style={styles.item}> Continuing Education </li></center>
                    <li style={styles.item}> Diversity </li>
                    <li style={styles.item}> Self-care </li>
                    </div>
                    <div>
                    <center><li style={styles.item}> Risk Management  </li></center>
                    <center><li style={styles.item}> Professional Development </li></center>
                    <center><li style={styles.item} id='psychotherapy'> Evidence-based Treatment</li></center>
                    </div>
                </ul>
                </FadeIn>
             </div>
             <Parallax
            //blur={10}
            bgImage={require('../../../resources/trainingsImg.jpg')}
            bgImageAlt="splash"
            strength={175}
            >
             <div className='trainingsImage' style={styles.trainingsImage}>

             </div>
             </Parallax>
        </div>
    )
}
