export default{
    introContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '1.2em',
        color: '#555',
        
        
    },
    head:{
        //fontWeight: '600',
        //textTransform: 'uppercase',
        fontFamily: 'Lobster',
        fontSize: '2.3em',
        
        
    },
    body: {
        margin: '1em',
        width: '60%',
        minWidth: '300px',
        textAlign: 'justify',
        lineHeight: '1.3em',
        fontSize: '1.2em',
        
        
    },
    call: {
        fontSize: '1.25em',
        
    }
}