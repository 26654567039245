import React from 'react';
import styles from './OurTeam.css.js';
import Member from './TeamMember/TeamMember.js';
import FadeIn from 'react-fade-in';

export default function OurTeam() {
    return (
        <div>
            <div className='teamContainer' style={styles.teamContainer}>
                <div className='team' style={styles.team}>
                    <center><h1 className='head' style={styles.head}>Our Team of Professionals</h1></center>
                </div>
                <FadeIn delay='100' transitionDuration='1500'>
                <Member />
                </FadeIn>
            </div>
        </div>
        
    )
}
