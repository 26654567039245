import React from 'react'; 
import styles from './Psychotherapy.css.js';
import FadeIn from 'react-fade-in';
import { Parallax } from 'react-parallax';

export default function TherapyMobile() {
    return (
        <div className='psychotherapyContainer' style={styles.psychotherapyContainerMobile}>
             <Parallax
            //blur={10}
            bgImage={require('../../../resources/testingMobile.jpg')}
            bgImageAlt="splash"
            strength={150}
            >
             <div className='psychotherapyImage' style={styles.psychotherapyImage}>

            </div>
            </Parallax>
             <div className='psychotherapyCopy' style={styles.psychotherapyCopyMobile}>
             <FadeIn delay='2000' transitionDuration='2000' >
                <h1 style={styles.head}>Psychotherapy</h1>
                <ul style={styles.itemContainer}>
                    <div>
                        <li style={styles.item}> Depression </li>
                        <li style={styles.item}> Anxiety </li>
                        <li style={styles.item}> Trauma </li>
                    </div>
                    <div>
                        <li style={styles.item}> Stress </li>
                        <center><li style={styles.item} id='team'> Other Mental Health </li></center>
                    </div>
                </ul>
                </FadeIn>
             </div>
        </div>
    )
}
