import React from 'react';
import styles from './ReachUs.css.js';
import logo from '../../../resources/sparksLogo.png';
import { Parallax } from 'react-parallax';

export default class ReachMobile extends React.Component {
    constructor(){
        super();
        this.state ={
            showMoreDisclaimer: false,
        };

        this.showMoreDisclaimer = this.showMoreDisclaimer.bind(this);
        this.closeMoreDisclaimer = this.closeMoreDisclaimer.bind(this);
    }

    showMoreDisclaimer(event){
        event.preventDefault();
        this.setState({ showMoreDisclaimer: true}, () => {
            document.addEventListener('click', this.closeMoreDisclaimer)
        } )
    }

    closeMoreDisclaimer() {
    
        this.setState({ showMoreDisclaimer: false }, () => {
          document.removeEventListener('click', this.closeMoreDisclaimer);
        });
        
      }

    render(){
    return (
        <div className='reachContainer' style={styles.reachContainerMobile}>
            <div className='copyContainer' style={styles.copyContainerMobile}>
                <div style={styles.logoContainer}>
                <img src={logo} alt='logo' style={styles.logo} />
                <center><h1 style={styles.head}>Connect with our team of professionals</h1></center>
                </div>
                <div style={styles.contactContainer}>
                <h2 style={styles.copy}>8118 Corporate Way <br /> Suite 121 <br /> Mason, Ohio 45040</h2>
                <span style={styles.phone}><a href='tel:513-297-3455' style={styles.phoneNum}>513.297.3455</a> &nbsp; or &nbsp; <a href='tel:513-492-9397' style={styles.phoneNum}>513.492.9397</a></span>
                <a href='mailto:officemanager@sparkspsychservices.com' style={styles.email}>officemanager@sparkspsychservices.com</a>
                <button onClick={this.showMoreDisclaimer} style={styles.button}>Disclaimer</button>
                
                <div>
                    {
                        this.state.showMoreDisclaimer
                        ?(
                            <div
                            className='more'
                            style={styles.showMoreDisclaimer}
                            ref={(element) => {
                                this.dropdownMenu = element;
                            }}
                            > 
                            <h3 style={styles.noteMobile}>If you are experiencing a psychological crisis or emergency please contact 911 or go to your nearest emergency room. All material on this website and each of its pages are for informational purposes only. The information is not intended to create, and receipt of it does not constitute, a psychologist-client relationship or any other professional, confidential, or privileged relationship.   By contacting Sparks Psych Services (SPS) by email i give my informed consent to use electronic methods for communicating with SPS. Please click <a href='http://www.sparkspsychservices.com/informedConsent.pdf' target='_blank' rel="noopener noreferrer" style={styles.link}>here</a> for details.</h3>
                            </div>
                        ) : (
                            null
                        )
                    }
                </div>
                <a href='http://www.sparkspsychservices.com/HIPAA.pdf' target='_blank' rel="noopener noreferrer" style={styles.link}><button style={styles.button}>Notice of Privacy Policy</button></a>
                <a href='http://www.sparkspsychservices.com/goodFaithEstimate.pdf' target='_blank' rel="noopener noreferrer" style={styles.link}><button style={styles.button}>Good Faith Estimate</button></a>
                </div>
               
                
            </div>
            <Parallax
            //blur={10}
            bgImage={require('../../../resources/sparksOffice.jpg')}
            bgImageAlt="splash"
            strength={50}
            >
            <div className='imageContainer' style={styles.imageContainer}>

            </div>
            </Parallax>
        </div>
    )
}
}