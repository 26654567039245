import React from 'react';
import styles from './Footer.css.js';
import logo from '../../../../src/resources/sparksLogoBlue2.png'

export default function Footer() {
    return (
        <div className='footContainer' style={styles.footContainer}>
            <div className='footMain' style={styles.footMain}>
                <a href='/'><img src={logo} alt='logo' style={styles.logo} /></a>
                <div>
                <h1 style={styles.head}>Quick Links</h1>
            <ul>
                <a href="#forensic" style={styles.link}><li style={styles.item}> Forensic Evaluations </li> </a>
                <a href="#psych" style={styles.link}><li style={styles.item}> Psychological Testing </li></a>
                <a href="#trainings" style={styles.link}><li style={styles.item}> Trainings </li></a>
                <a href="#psychotherapy" style={styles.link}><li style={styles.item}> Psychotherapy </li></a>
                <a href="#team" style={styles.link}><li style={styles.item}> Our Team</li></a>
                <a href="#contact" style={styles.link}><li style={styles.item}> Reach Us </li></a>
            </ul>
            </div>
            </div> 
            <div>
                <center><h2 style={styles.copyright}>Copyright {'\u00A9'} 2023, Sparks Psychological Services, LLC</h2></center>
            </div>
        </div>
    )
}
