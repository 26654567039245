import React from 'react';
import styles from './PsychologicalTesting.css.js';
import FadeIn from 'react-fade-in';
import { Parallax } from 'react-parallax';

export default function TestingDesktop() {
    return (
        <div className='psychContainer' style={styles.psychContainerMobile}>
        <Parallax
            //blur={10}
            bgImage={require('../../../resources/testingFinal.jpg')}
            bgImageAlt="splash"
            strength={150}
            >
        <div className='psychImage' style={styles.psychImageMobile}>
        </div>
        </Parallax>
        <div className='psychCopy' style={styles.psychCopyMobile}>
        <FadeIn delay='2000' transitionDuration='2000' >
           <h1 style={styles.head}>Testing</h1>
           <ul style={styles.itemContainer}>
                <div>
                <center><li style={styles.item}> Ministry - Religious </li></center>
                <li style={styles.item}> Diagnostic </li>
                <li style={styles.item}> Academic </li>
                <li style={styles.item}> Intellectual </li>
               </div>
               <div>
                <li style={styles.item}> Pre-employment</li>
                <li style={styles.item}> Vocational </li>
                <li style={styles.item} id='trainings'> Neuropsychology </li>
               </div>
           </ul>
           </FadeIn>
        </div>
        
   </div>
    )
}
