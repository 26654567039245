import React from 'react';
import Intro from './Intro/Intro.js'
import Splash from './Splash/Splash.js';
import Forensic from './ForensicEvaluations/ForensicEvaluations.js';
import Team from './OurTeam/OurTeam.js';
import Psych from './PsychologicalTesting/PsychologicalTesting.js';
import Contact from './ReachUs/ReachUs.js';
import Trainings from './Trainings/Trainings.js';
import Psychotherapy from './Psychotherapy/Psychotherapy.js';
import Foot from './Footer/Footer.js';
import styles from './Home.css.js';
import { ParallaxProvider } from 'react-scroll-parallax';

export default function () {
    return (
        <ParallaxProvider>
            <div style={styles.homeContainer}>
            <Splash />
            <Intro />
            <Forensic />
            <Psych />
            <Trainings />
            <Psychotherapy />
            <Team />
            <Contact />
            <Foot />
            </div>
        </ParallaxProvider> 
        
    )
}
