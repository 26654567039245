import React from 'react';
import './App.css';
import useSticky from "../hooks/useSticky.js";
import Welcome from "../components/Home/Header/Welcome.js";
import NavBar from "../components/Home/Header/NavBar.js";
import { ParallaxProvider } from 'react-scroll-parallax';



function App() {
  const { isSticky, element } = useSticky()
  return (
    <ParallaxProvider>
   
      <NavBar sticky={isSticky} />
      <Welcome element={element} />
    
    </ParallaxProvider>
  )
}

export default App;
