export default{
    parallaxContainer:{
        width: '100vw', 
        height: '81vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',

    },
    parallaxCopy1:{
        margin: '.2rem',
        color: '#ebdaad',
        fontSize: '4.5em',
        textTransform: 'uppercase',
        textAlign: 'center',
        //padding: '0 3rem',
        width: '300px',
        zIndex: '2',
        
        
    },
    parallaxCopy2:{
        margin: '.2rem',
        color: '#ebdaad',
        fontSize: '3em',
        textTransform: 'uppercase',
        textAlign: 'center',
        //padding: '0 3rem',
        width: '300px',
        zIndex: '2',
        paddingTop: '.8em',

    },
    parallaxCopy3:{
        margin: '.2rem',
        color: '#ebdaad',
        fontSize: '2.95em',
        textTransform: 'uppercase',
        textAlign: 'center',
        //padding: '0 3rem',
        width: '300px',
        zIndex: '2',

    },
    parallaxCopy4:{
        margin: '.2rem',
        color: '#ebdaad',
        fontSize: '2.4em',
        textTransform: 'uppercase',
        textAlign: 'center',
        //padding: '0 3rem',
        width: '300px',
        zIndex: '2',

    },
    parallaxOpacity:{
      backgroundColor: 'rgba(20,50,87,0.6)',
      width: '300px',
      height: '300px',
      display: 'flex',
      flexDirection: 'column',
      zIndex: '1',
      justifyConent: 'center',
      alignItems: 'center',

    },
    forensicLink:{
        margin: '5em',
    }
}