import React from "react"
import styles from  "./Welcome.css.js"
import Logo from "../../../resources/sparksLogoBlue2.png"
import Home from '../../../components/Home/Home.js'

const Welcome = ({ element }) => {
  return (
    <main>
      <section className="welcome">
        <div ref={element} className='header' style={styles.headerContainer}>
        <a href='/'><img className='logo' src={Logo} style={styles.logo} alt="Sparks Logo" /> </a>
        </div>
      </section>
      <Home />
    </main>
  )
}

export default Welcome 