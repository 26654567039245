

export default {
    footContainer: {
        background: 'linear-gradient(180deg, #fff, #a1ccdd)', //#e7eeed
        //backgroundColor: '#143257',
        height: '195px',
        boxShadow: '4px 0 8px 0 rgba(0, 0, 0, 0.4), 6px 0 20px 0 rgba(0, 0, 0, 0.39)',
        
        
    },
    footMain:{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: '1em',
        color: '#555',
        
    },
    logo : {
        maxHeight: '125px',
        maxWidth: '30vw',
       
    },
    copyright: {
        color: '#555',
        fontSize: '.8em',
        
    },
    head:{
        textTransform: 'uppercase',
        padding: '.25em',
        
    },
    link:{
        textDecoration: 'none',
        
    },
    item: {
        listStyleType: 'circle',
        paddingTop: '.25em',
        color: '#555',
        
       
    }
}