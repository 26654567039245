export default {
    reachContainer:{
        margin: '1.5em',
        minHeight: '400px',
        backgroundColor: '#424242',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        display: 'flex',
        borderRadius: '2em 0'
    },
    reachContainerMobile:{
        margin: '1.5em',
        minHeight: '400px',
        backgroundColor: '#424242',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '2em 0'
    },
    imageContainer: {
        width: '70vw', 
        height: '400px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    /*
    imageContainer: {
        width: '100%',
        //height: "500px",
        backgroundImage: `url(${reachImg})`,
        backgroundSize: 'cover',
        backgroundPosition: ' top right',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    */
    copyContainer: {
        width: '40%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    copyContainerMobile: {
        //display: 'flex',
        alignItems: 'space-around',
        justifyContent: 'space-around'
    },
    logoContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    contactContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    head: {
        textTransform: 'uppercase',
        color: '#E6B277',
        margin: '1em',
        fontWeight: '600',
        fontSize: '1em',
        maxWidth: '200px'
    },
    title: {
        color: '#fff8e5',
        textTransform: 'uppercase',
        margin: '.75em',
        fontWeight: '600'
    },
    copy:{
        color: '#fff8e5',
        textTransform: 'uppercase',
        //margin: '1em',
    },
    note: {
        fontSize: '9px',
        color: 'white',
        //width: '70%',
        textTransform: 'uppercase',
        padding: '.5em',
        textAlign: 'justify',
    },
    noteMobile: {
        fontSize: '9px',
        color: 'white',
        maxWidth: '450px',
        textTransform: 'uppercase',
        padding: '.5em',
        textAlign: 'justify',
    },
    button: {
        margin: '.3em',
        width: '180px',
        cursor: 'pointer',
        color: '#222',
        textDecoration: 'none'
    },
    link:{
        color: 'white'
    },
    phone:{
        color: '#143257',
        backgroundColor: '#fff8e5',
        margin: '1em 0',
        padding: '.5em 0',
        width: '100%',
        fontSize: '1.25em',
        display: 'flex',
        justifyContent: 'center'
    },
    phoneNum:{
        textDecoration: 'none',
    },
    email:{
        color: '#fff8e5',
        textDecoration: 'none',
        padding: '.2em'

    },
    logo: {
        height: '100px'
    },
}