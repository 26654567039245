import React from 'react';
import Media from'react-media';
import ReachDesktop from './ReachDesktop.js';
import ReachMobile from './ReachMobile.js'

export default class ReachUs extends React.Component {

    render(){
    return (
        <Media queries={{ small: { maxWidth: 840 } }}>
                {matches =>
                    matches.small ? (
                        <ReachMobile />
                    ) : (
                        <ReachDesktop />
                    )
                }
                </Media>   
    )
}
}