import React from 'react'; 
import styles from './ForensicEvaluations.css.js';
import FadeIn from 'react-fade-in';
import { Parallax } from 'react-parallax';

export default function ForensicMobile() {

    return (
        <div className='forensicContainer' style={styles.forensicContainerMobile}>
             <div className='forensicCopy' style={styles.forensicCopyMobile}>
                 <FadeIn delay='2000' transitionDuration='2000' >
                <h1 style={styles.head}>Forensic</h1>
                <ul style={styles.itemContainer}>
                    <div>
                    <center><li style={styles.item}> Court Referrals </li></center>
                    <li style={styles.item}> Competency </li>
                    <li style={styles.item}> Sanity </li>
                    </div>
                    <div>
                    <center><li style={styles.item}> Risk Assessment </li></center>
                    <li style={styles.item}> Work-related </li>
                    <li style={styles.item} id='psych'> Immigration </li>
                    </div>
                </ul>
                </FadeIn>
             </div>
             <Parallax
            //blur={1}
            bgImage={require('../../../resources/scales3.jpg')}
            bgImageAlt="splash"
            strength={175}
            >
             <div className='forensicImage' style={styles.forensicImageMobile}>
                
             </div>
             </Parallax>
        </div>
    )
}