import React, { useEffect, useState, useRef } from "react"
import "./NavBar.css"
import Logo from "../../../resources/sparksLogoSticky.png"

function NavBar() {
  const [isSticky, setSticky] = useState(false)
  const element = useRef(null)

  useEffect(() => {
      window.scrollY > 50 && window.innerWidth > 790
        ? setSticky(true)
        : setSticky(false)
  });

  console.log(window.scrollY)
  return (
    <div>
      <nav className={isSticky ? "navbar navbar-sticky" : "navbar"}>
        <div className="navbar--logo-holder">
          {isSticky ? <a href='/'><img src={Logo} alt="logo" className="navbar--logo" /> </a> : null}
        </div> 
        <div>
          {isSticky ? 
          <ul className="navbar--link">
            <a href="#forensic" className="navbar--link-item">Forensic Evaluations</a>
            <a href="#psych" className="navbar--link-item">Psychological Testing</a>
            <a href="#trainings" className="navbar--link-item">Trainings</a>
            <a href="#psychotherapy" className="navbar--link-item">Psychotherapy</a>
            <a href="#team" className="navbar--link-item">Our Team</a>
            <a href="#contact" className="navbar--link-item">Reach Us</a>
          </ul>
          : null}
        </div>
      
      </nav>
    </div>
  )
}

export default NavBar