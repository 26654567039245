import React from 'react';
import Slider from "react-slick";
import styles from '../OurTeam.css.js';
import blank from '../../../../resources/Blank.png';
import sparks from '../../../../resources/SparksSq.jpg';
import porter from '../../../../resources/porterSq1.jpg';
import bergman from '../../../../resources/bergmanSq.jpg'
import billman from '../../../../resources/BillmannSq.jpg';
import barber from '../../../../resources/BarberSq.jpg';
import westrich from '../../../../resources/Westrich-PurdySq.jpg'; 
import twehues from '../../../../resources/TwehuesSq.jpg';
import trice from '../../../../resources/AmandatriceSq.jpg';
import weisman from '../../../../resources/weisman1.jpg';
import doninger from '../../../../resources/doninger.jpg';
import jen from '../../../../resources/sparksJen.jpg';
import wilson from '../../../../resources/wilson.jpg';
import zeitchick from '../../../../resources/zeitchick.jpg';
import lengerich from '../../../../resources/lengerich.jpg';
import nichting from '../../../../resources/nichting.jpg';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "transparent", opacity: '.15'}}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "transparent", zIndex:'2', opacity: '.15' }}
      onClick={onClick}
    />
  );
}

export default class TeamMember extends React.Component {
    constructor() {
        super();
        this.state = {
          showMoreSteve: false,
          showMoreMarissa: false,
          showMoreBrittany: false,
          showMoreBarbra: false,
          showMoreStephen: false,
          showMoreNick: false,
          showMoreMichelle: false,
          showMoreNicole: false,
          showMoreJennifer: false,
          showMoreAmanda: false,
          showMoreJessica: false,
          showMoreJason: false,
          showMoreKristen: false,
          showMoreMarjorie: false,
          showMoreTawnee: false,
          showMoreZeitchick: false,
          showMoreLengerich: false,
          showMoreNichting: false,
          showMorePorter: false,
        };
        
        this.showMoreSteve = this.showMoreSteve.bind(this);
        this.showMoreMarissa = this.showMoreMarissa.bind(this);
        this.showMoreBarbra = this.showMoreBarbra.bind(this);
        this.showMoreBrittany = this.showMoreBrittany.bind(this);
        this.showMoreStephen = this.showMoreStephen.bind(this);
        this.showMoreNick = this.showMoreNick.bind(this);
        this.showMoreMichelle = this.showMoreMichelle.bind(this);
        this.showMoreNicole = this.showMoreNicole.bind(this);
        this.showMoreJennifer = this.showMoreJennifer.bind(this);
        this.showMoreAmanda = this.showMoreAmanda.bind(this);
        this.showMoreJessica = this.showMoreJessica.bind(this);
        this.showMoreJason = this.showMoreJason.bind(this);
        this.showMoreKristen = this.showMoreKristen.bind(this);
        this.showMoreMarjorie = this.showMoreMarjorie.bind(this);
        this.showMoreTawnee = this.showMoreTawnee.bind(this);
        this.showMoreZeitchick = this.showMoreZeitchick.bind(this);
        this.showMoreLengerich = this.showMoreLengerich.bind(this);
        this.showMoreNichting = this.showMoreNichting.bind(this);
        this.showMorePorter = this.showMorePorter.bind(this);

        this.closeMoreSteve = this.closeMoreSteve.bind(this);
        this.closeMoreMarissa = this.closeMoreMarissa.bind(this);
        this.closeMoreBarbra = this.closeMoreBarbra.bind(this);
        this.closeMoreBrittany = this.closeMoreBrittany.bind(this);
        this.closeMoreStephen = this.closeMoreStephen.bind(this);
        this.closeMoreNick = this.closeMoreNick.bind(this);
        this.closeMoreMichelle = this.closeMoreMichelle.bind(this);
        this.closeMoreNicole = this.closeMoreNicole.bind(this);
        this.closeMoreJennifer = this.closeMoreJennifer.bind(this);
        this.closeMoreAmanda = this.closeMoreAmanda.bind(this);
        this.closeMoreJessica = this.closeMoreJessica.bind(this);
        this.closeMoreJason = this.closeMoreJason.bind(this);
        this.closeMoreKristen = this.closeMoreKristen.bind(this);
        this.closeMoreMarjorie = this.closeMoreMarjorie.bind(this);
        this.closeMoreTawnee = this.closeMoreTawnee.bind(this);
        this.closeMoreZeitchick = this.closeMoreZeitchick.bind(this);
        this.closeMoreLengerich = this.closeMoreLengerich.bind(this);
        this.closeMoreNichting = this.closeMoreNichting.bind(this);
        this.closeMorePorter = this.closeMorePorter.bind(this);
      }

      showMoreNick(event) {
        event.preventDefault();
        
        this.setState({ showMoreNick: true }, () => {
          document.addEventListener('click', this.closeMoreNick);
        });
      }
      
      closeMoreNick() {
        
        this.setState({ showMoreNick: false }, () => {
          document.removeEventListener('click', this.closeMoreNick);
        });
        
      }

      showMoreBrittany(event) {
        event.preventDefault();
        this.setState({ showMoreBrittany: true }, () => {
          document.addEventListener('click', this.closeMoreBrittany);
        });
      }
      
      closeMoreBrittany() {
        this.setState({ showMoreBrittany: false }, () => {
          document.removeEventListener('click', this.closeMoreBrittany);
        });
      }

      showMoreMichelle(event) {
        event.preventDefault();
        
        this.setState({ showMoreMichelle: true }, () => {
          document.addEventListener('click', this.closeMoreMichelle);
        });
      }
      
      closeMoreMichelle() {
        
        this.setState({ showMoreMichelle: false }, () => {
          document.removeEventListener('click', this.closeMoreMichelle);
        });
        
      }

      showMoreNicole(event) {
        event.preventDefault();
        
        this.setState({ showMoreNicole: true }, () => {
          document.addEventListener('click', this.closeMoreNicole);
        });
      }
      
      closeMoreNicole() {
        
        this.setState({ showMoreNicole: false }, () => {
          document.removeEventListener('click', this.closeMoreNicole);
        });
        
      }

      showMoreJennifer(event) {
        event.preventDefault();
        
        this.setState({ showMoreJennifer: true }, () => {
          document.addEventListener('click', this.closeMoreJennifer);
        });
      }
      
      closeMoreJennifer() {
        
        this.setState({ showMoreJennifer: false }, () => {
          document.removeEventListener('click', this.closeMoreJennifer);
        });
        
      }

      showMoreAmanda(event) {
        event.preventDefault();
        
        this.setState({ showMoreAmanda: true }, () => {
          document.addEventListener('click', this.closeMoreAmanda);
        });
      }
      
      closeMoreAmanda() {
        
        this.setState({ showMoreAmanda: false }, () => {
          document.removeEventListener('click', this.closeMoreAmanda);
        });
        
      }

      showMoreJessica(event) {
        event.preventDefault();
        
        this.setState({ showMoreJessica: true }, () => {
          document.addEventListener('click', this.closeMoreJessica);
        });
      }
      
      closeMoreJessica() {
        
        this.setState({ showMoreJessica: false }, () => {
          document.removeEventListener('click', this.closeMoreJessica);
        });
        
      }

      showMoreJason(event) {
        event.preventDefault();
        
        this.setState({ showMoreJason: true }, () => {
          document.addEventListener('click', this.closeMoreJason);
        });
      }
      
      closeMoreJason() {
        
        this.setState({ showMoreJason: false }, () => {
          document.removeEventListener('click', this.closeMoreJason);
        });
        
      }

      showMoreKristen(event) {
        event.preventDefault();
        
        this.setState({ showMoreKristen: true }, () => {
          document.addEventListener('click', this.closeMoreKristen);
        });
      }
      
      closeMoreKristen() {
        
        this.setState({ showMoreKristen: false }, () => {
          document.removeEventListener('click', this.closeMoreKristen);
        });
        
      }

      showMoreMarjorie(event) {
        event.preventDefault();
        
        this.setState({ showMoreMarjorie: true }, () => {
          document.addEventListener('click', this.closeMoreMarjorie);
        });
      }
      
      closeMoreMarjorie() {
        
        this.setState({ showMoreMarjorie: false }, () => {
          document.removeEventListener('click', this.closeMoreMarjorie);
        });
        
      }

      showMoreTawnee(event) {
        event.preventDefault();
        
        this.setState({ showMoreTawnee: true }, () => {
          document.addEventListener('click', this.closeMoreTawnee);
        });
      }
      
      closeMoreTawnee() {
        
        this.setState({ showMoreTawnee: false }, () => {
          document.removeEventListener('click', this.closeMoreTawnee);
        });
        
      }
      
      showMoreSteve(event) {
        event.preventDefault();
        
        this.setState({ showMoreSteve: true }, () => {
          document.addEventListener('click', this.closeMoreSteve);
        });
      }
      
      closeMoreSteve() {
        
        this.setState({ showMoreSteve: false }, () => {
          document.removeEventListener('click', this.closeMoreSteve);
        });
        
      }
    
      showMoreMarissa(event) {
        event.preventDefault();
        
        this.setState({ showMoreMarissa: true }, () => {
          document.addEventListener('click', this.closeMoreMarissa);
        });
      }
      
      closeMoreMarissa() {
        
        this.setState({ showMoreMarissa: false }, () => {
          document.removeEventListener('click', this.closeMoreMarissa);
        });
        
      }

      showMoreBarbra(event) {
        event.preventDefault();
        
        this.setState({ showMoreBarbra: true }, () => {
          document.addEventListener('click', this.closeMoreBarbra);
        });
      }
      
      closeMoreBarbra() {
        
        this.setState({ showMoreBarbra: false }, () => {
          document.removeEventListener('click', this.closeMoreBarbra);

        });
        
      }

      showMoreStephen(event) {
        event.preventDefault();
        
        this.setState({ showMoreStephen: true }, () => {
          document.addEventListener('click', this.closeMoreStephen);
        });
      }
      
      closeMoreStephen() {
        
        this.setState({ showMoreStephen: false }, () => {
          document.removeEventListener('click', this.closeMoreStephen);

        });
        
      }

      showMoreZeitchick(event) {
        event.preventDefault();
        
        this.setState({ showMoreZeitchick: true }, () => {
          document.addEventListener('click', this.closeMoreZeitchick);
        });
      }
      
      closeMoreZeitchick() {
        
        this.setState({ showMoreZeitchick: false }, () => {
          document.removeEventListener('click', this.closeMoreZeitchick);

        });
        
      }

      showMoreLengerich(event) {
        event.preventDefault();
        
        this.setState({ showMoreLengerich: true }, () => {
          document.addEventListener('click', this.closeMoreLengerich);
        });
      }
      
      closeMoreLengerich() {
        
        this.setState({ showMoreLengerich: false }, () => {
          document.removeEventListener('click', this.closeMoreLengerich);

        });
        
      }

      showMoreNichting(event) {
        event.preventDefault();
        
        this.setState({ showMoreNichting: true }, () => {
          document.addEventListener('click', this.closeMoreNichting);
        });
      }
      
      closeMoreNichting() {
        
        this.setState({ showMoreNichting: false }, () => {
          document.removeEventListener('click', this.closeMoreNichting);

        });
        
      }
      
      showMorePorter(event) {
        event.preventDefault();
        
        this.setState({ showMorePorter: true }, () => {
          document.addEventListener('click', this.closeMorePorter);
        });
      }
      
      closeMorePorter() {
        
        this.setState({ showMorePorter: false }, () => {
          document.removeEventListener('click', this.closeMorePorter);

        });
        
      }
    
      
    
    
    render(){
      var settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 1310,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 1050,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                initialSlide: 2
              }
            },
            {
              breakpoint: 790,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
                breakpoint: 525,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
          ]
        };
    return (
      <div id='teamSlider'>
        <div className='slider'>
        
        <div className='memberContainer' style={styles.memberContainer}> 
          <Slider {...settings} style={styles.slider}>
            <div className ='whole'  style={styles.whole}> 
            <div className='bioContainer' style={styles.bioContainer}>
                {
                    this.state.showMoreSteve
                    ?(
                        <div
                            className='more'
                            style={styles.bioContainer}
                            ref={(element) => {
                                this.dropdownMenu = element;
                            }}>
                                <h2 style={styles.bioHead}>About Steve Sparks</h2>
                <p style={styles.bioCopy}>Steve Sparks, Ph.D., ABPP, completed his undergraduate education at the University of Notre Dame, graduating Magna Cum Laude. He received scholarships thereafter and earned both graduate degrees, Master’s and Doctorate, at the University of Cincinnati. He is board certified in forensic psychology by the American Board of Professional Psychology.

Dr. Sparks was formerly the Director of Forensic Services at Forensic and Mental Health Services, Inc., the state-sponsored forensic center in Butler County, Ohio. He is current Adjunct Assistant Professor of Psychology at the Mount St. Joseph University where he teaches Forensic Psychology and former adjunct at the University of Cincinnati. His book chapter Creating and Sustaining an Assessment/Forensic Based Practice was published in 2017 (in Sears. R. & Ossege, J.-Eds.) His work has also been published in the Journal of Applied Social Psychology and the Ohio Psychologist. He has provided psychological assessment services to individuals, private attorneys, forensic psychology centers in Kentucky and Ohio, Ohio Attorney General’s office, the United States Public Health Service, Bureau of Workers’ Compensation,  Social Security Administration,  Catholic Archdiocese of Cincinnati, and the Bureau of Vocational Rehabilitation among many others. He is Past President of the Board of Directors of the Cincinnati Academy of Professional Psychology.

Dr. Sparks specializes in psychological evaluations of many kinds. These services include, but are not limited to cases involving personal, civil, criminal, pre-employment, work-related, neuropsychological, and disability issues. </p>
                            </div>    
                    ):(
                      <div className='member' style={styles.member} onClick={this.showMoreSteve}>
                      <img src={sparks} style={styles.memberImg} alt="Sparks" />
                      
                      <h1 style={styles.name}>Steve Sparks</h1>
                      <h2 style={styles.title} id='contact'>Ph.D., ABPP</h2>
                      
                  </div>
                    )
                }
                
            </div>
            </div>

            <div className='wholeMember'>
            
            <div className='bioContainer' style={styles.bioContainer}>
                {
                    this.state.showMoreBrittany
                    ?(
                        <div
                            className='more'
                            style={styles.bioContainer}
                            ref={(element) => {
                                this.dropdownMenu = element;
                            }}>
                                <h2 style={styles.bioHead}>About Brittany Barber</h2>
                                <p style={styles.bioCopy}>Dr. Brittany Barber is a licensed clinical psychologist in Ohio. She
                                  obtained her Doctoral degree in Clinical Psychology from the
                                  University of the Cumberlands in 2018, completed her pre-doctoral
                                  training at the University of Cincinnati (UC) Counseling and
                                  Psychological Services, and post-doctoral training at UC’s
                                  University Health Services. She has trained in a variety of
                                  outpatient settings, including schools and community mental
                                  health clinics, and worked primarily with adolescents and adults.
                                  Dr. Barber currently specializes in assessment and treatment of
                                  ADHD, primarily in college students and adults. Her area of
                                  interest includes assessment for standardize testing purposes
                                  (SAT, ACT, GRE, LSAT, MCAT, and the BAR Exam), adult
                                  giftedness, and late in life (adult) diagnosis for ADHD. Dr. Barber’s
                                  clinical therapeutic interests include mood and anxiety disorders,
                                  life transitions, interpersonal relationships, and using mindfulness
                                  to improve balance in life. 
                                </p>
                            </div>    
                    ):(
                      <div className='member' style={styles.member} onClick={this.showMoreBrittany}>
                      <img src={barber} style={styles.memberImg} alt="Bittany Barber" />
                      <h1 style={styles.name}>Brittany Barber</h1>
                      <h2 style={styles.title}>Ph.D.</h2>
                  </div>
                    )
                }
            </div>
            </div>

            <div className='wholeMember'>
            
            <div className='bioContainer' style={styles.bioContainer}>
                {
                    this.state.showMoreBarbra
                    ?(
                        <div
                            className='more'
                            style={styles.bioContainer}
                            ref={(element) => {
                                this.dropdownMenu = element;
                            }}>
                                <h2 style={styles.bioHead}>About Barbra Bergman</h2>
                <p style={styles.bioCopy}>Dr. Bergman was raised in Cleveland, Ohio and graduated with a Bachelor of Arts degree from the Cleveland State University. Her Master’s and Doctorate degrees were conferred at the Ohio State University. She has been a licensed clinical psychologist in Ohio since 1976. Dr. Bergman has practiced as a forensic psychologist for the past 35 years, with experience in evaluation and expert testimony in cases involving adults and children in both civil and criminal courts. Her clinical specialty is assessment and treatment of both victims and perpetrators of sexual abuse and other forms of trauma. </p>
                            </div>    
                    ):(
                      <div className='member' style={styles.member} onClick={this.showMoreBarbra}>
                      <img src={bergman} style={styles.memberImg} alt="Bergman" />
                      <h1 style={styles.name}>Barbra Bergman</h1>
                      <h2 style={styles.title}>Ph.D.</h2>
                  </div>
                    )
                }
            </div>
            </div>

            <div className='wholeMember'>
            
            <div className='bioContainer' style={styles.bioContainer}>
                {
                    this.state.showMoreStephen
                    ?(
                        <div
                            className='more'
                            style={styles.bioContainer}
                            ref={(element) => {
                                this.dropdownMenu = element;
                            }}>
                                <h2 style={styles.bioHead}>About Stephen J. Billmann</h2>
                <p style={styles.bioCopy}>Dr. Billmann is a licensed psychologist with more than 20 years of experience. He evaluates individuals for work capacity, diagnostic questions, fitness for duty, and behavioral sleep disorders. He has also treated individuals with a variety of Behavioral Medicine issues. He is board-certified by the American Board of Sleep Medicine.

Dr. Billmann received his Doctor of Psychology degree from Virginia Consortium offered by Eastern Virginia Medical School, William & Mary, Old Dominion University & Norfolk State University. He completed a residency in behavioral medicine at Temple University Hospital and a fellowship at the Sentara-Eastern Virginia Medical School Sleep Disorders Center. </p>
                            </div>    
                    ):(
                      <div className='member' style={styles.member} onClick={this.showMoreStephen}>
                      <img src={billman} style={styles.memberImg} alt="Billman" />
                      <h1 style={styles.name}>Stephen J. Billmann</h1>
                      <h2 style={styles.title}>Psy.D., A.B.S.M.</h2>
                  </div>
                    )
                }
            </div>
            </div>

            <div className='wholeMember'>
              
              <div className='bioContainer' style={styles.bioContainer}>
                  {
                      this.state.showMoreNick
                      ?(
                          <div
                              className='more'
                              style={styles.bioContainer}
                              ref={(element) => {
                                  this.dropdownMenu = element;
                              }}>
                                  <h2 style={styles.bioHead}>About Nick Doninger</h2>
                  <p style={styles.bioCopy}>Nicholas A. Doninger, Ph.D., ABPP is a board-certified in Clinical Neuropsychology with the American Board of Professional Psychology. He received an undergraduate degree in psychology from Boston College and a doctoral degree from Finch University of Health Sciences/Chicago Medical School with a specialization in clinical neuropsychology. Dr. Doninger completed internship training at the Cleveland Veteran Affairs Medical Center and a postdoctoral fellowship in neuropsychology at The University of Chicago Medical Center. He is a licensed psychologist in the state of Ohio and also works with Health Psychology Associates, part of Premier Physician Network at Miami Valley Hospital in Dayton. In addition, he holds an academic rank of Assistant Professor in the Department of Psychiatry at Wright State University’s Boonshoft School of Medicine.  Dr. Doninger conducts Independent Medical Evaluations for insurance companies through third parties and has worked with organizations to provide evaluations of veterans with claimed disabilities, including Post Traumatic Stress Disorder and Traumatic Brain Injury. His work includes forensic neuropsychological evaluations as part mitigation for capital cases, personal injury claims, and civil capacities. </p>
                              </div>    
                      ):(
                        <div className='member' style={styles.member} onClick={this.showMoreNick}>
                        <img src={doninger} style={styles.memberImg} alt="Doninger" />
                        <h1 style={styles.name}>Nick Doninger</h1>
                        <h2 style={styles.title}>Ph.D., ABPP</h2>
                    </div>
                      )
                  }
              </div>
              </div>
              <div className='wholeMember'>
              
              <div className='bioContainer' style={styles.bioContainer}>
                  {
                      this.state.showMoreLengerich
                      ?(
                          <div
                              className='more'
                              style={styles.bioContainer}
                              ref={(element) => {
                                  this.dropdownMenu = element;
                              }}>
                                  <h2 style={styles.bioHead}>About Theresa Lengerich</h2>
                  <p style={styles.bioCopy}>Dr. Lengerich is a clinical psychologist, licensed in Ohio since 1989.  She earned her Bachelors degree from Miami University and her professional psychology degree from Wright State University.  Her career has has been focused in medical settings, hospitals and the VA, addressing chronic illness, obesity, insomnia, geriatrics and disability. She has been an educator and in family medicine facilitating doctor patient communication to enhance whole patient care.  In addition to addressing clinical disorders, she employs positive psychology and goal setting  for health improvement and/or athletic performance for clients who aspire to “live their best life”.  She has competed in swimming at the 2023 National Senior Games.  </p>
                              </div>    
                      ):(
                        <div className='member' style={styles.member} onClick={this.showMoreLengerich}>
                        <img src={lengerich} style={styles.memberImg} alt="Lengerich" />
                        <h1 style={styles.name}>Theresa Lengerich</h1>
                        <h2 style={styles.title}>Psy.D.</h2>
                    </div>
                      )
                  }
              </div>
              </div>

              
            <div className='wholeMember'>
            
            <div className='bioContainer' style={styles.bioContainer}>
                {
                    this.state.showMoreNichting
                    ?(
                        <div
                            className='more'
                            style={styles.bioContainer}
                            ref={(element) => {
                                this.dropdownMenu = element;
                            }}>
                                <h2 style={styles.bioHead}>About Erin Nichting</h2>
                <p style={styles.bioCopy}>Erin M. Nichting, Psy.D. earned her bachelor’s degree from the University of Dayton, graduating Magna Cum Laude and with Honors. She also has a master’s degree and doctorate degree from Wright State University School of Professional Psychology. Dr. Nichting has primarily done work in forensic psychology, focusing on completing forensic assessments for criminal courts, managing and implementing outpatient competency restoration programs, supervising students interested in forensic psychology, and guest lecturing on forensic topics. Dr. Nichting formerly held the position of Associate Director at a state certified forensic center. Currently, she completes forensic assessment work as an independent contractor for various agencies in Ohio, including working part-time for SPS, while holding the full-time position of Forensic Director at the Forensic Evaluation Service Center. Additionally, Dr. Nichting has an interest in women’s issues, particularly related to reproductive and postpartum concerns, and completes therapeutic work in this specialized area. </p>
                            </div>    
                    ):(
                      <div className='member' style={styles.member} onClick={this.showMoreNichting}>
                      <img src={nichting} style={styles.memberImg} alt="Nichting" />
                      <h1 style={styles.name}>Erin Nichting</h1>
                      <h2 style={styles.title}>Psy.D.</h2>
                      </div>
                    )
                }
            </div>
            </div>

            <div className='wholeMember'>
            
            <div className='bioContainer' style={styles.bioContainer}>
                {
                    this.state.showMorePorter
                    ?(
                        <div
                            className='more'
                            style={styles.bioContainer}
                            ref={(element) => {
                                this.dropdownMenu = element;
                            }}>
                                <h2 style={styles.bioHead}>About Shannon Porter</h2>
                <p style={styles.bioCopy}>Shannon L. Porter, Psy.D., ABPP is a licensed psychologist in Columbus, Ohio. She completed her graduate training at The Chicago School of Professional Psychology, where she earned her doctoral degree in Clinical Forensic Psychology (with a specialization in law enforcement) in 2014. She continued her specialized training in forensic assessment during her predoctoral internship and postdoctoral fellowship at Netcare Forensic Center, a community forensic evaluation center that provides criminal forensic evaluations and outpatient competency restoration services for several counties in Central Ohio. She currently serves as the Assistant Director of Forensic and Specialized Assessment Services at Netcare Forensic Center. She also works at Twin Valley Behavioral Healthcare, a psychiatric hospital in Columbus, Ohio. Her primary responsibilities there include evaluating individuals found incompetent to stand trial and court-ordered to undergo inpatient psychiatric treatment. Dr. Porter is a member of the American Psychology-Law Society, the Society for Police and Criminal Psychology, and the Association of Threat Assessment Professionals. She has been qualified as an expert witness in numerous courts throughout Ohio. She has experience providing education, consultation, and training to law enforcement, mental health professionals, educators, lawyers, and other professionals regarding a variety of psycholegal issues. She also serves on workgroups for the Ohio Supreme Court’s sentencing commission and a committee implementing changes associated with Senate Bill 2, which addresses competency restoration throughout Ohio. </p>
                            </div>    
                    ):(
                      <div className='member' style={styles.member} onClick={this.showMorePorter}>
                      <img src={porter} style={styles.memberImg} alt="Porter" />
                      <h1 style={styles.name}>Shannon L. Porter</h1>
                      <h2 style={styles.title}>Psy.D., ABPP</h2>
                      </div>
                    )
                }
            </div>
            </div>

            <div className='wholeMember'>
            
            <div className='bioContainer' style={styles.bioContainer}>
                {
                    this.state.showMoreJennifer
                    ?(
                        <div
                            className='more'
                            style={styles.bioContainer}
                            ref={(element) => {
                                this.dropdownMenu = element;
                            }}>
                                <h2 style={styles.bioHead}>About Jennifer Sparks</h2>
                <p style={styles.bioCopy}>Jennifer Sparks studied Fine Arts at University of Cincinnati before turning to management for a large corporate retailer, where she worked for 12 years achieving position of Manager of Hiring and Training. She has previously covered office management for SPS and currently serves as Accounts Manager. She keeps the financial wheels of SPS running smoothly. </p>
                            </div>    
                    ):(
                      <div className='member' style={styles.member} onClick={this.showMoreJennifer}>
                      <img src={jen} style={styles.memberImg} alt="Sparks" />
                      <h1 style={styles.name}>Jennifer Sparks</h1>
                  </div>
                    )
                }
            </div>
            </div>

            <div className='wholeMember'>
            
            <div className='bioContainer' style={styles.bioContainer}>
                {
                    this.state.showMoreJessica
                    ?(
                        <div
                            className='more'
                            style={styles.bioContainer}
                            ref={(element) => {
                                this.dropdownMenu = element;
                            }}>
                                <h2 style={styles.bioHead}>About Jessica Twehues</h2>
                <p style={styles.bioCopy}>Dr. Jessica Twehues is a licensed clinical psychologist in Ohio and Kentucky. She graduated Magna Cum Laude from Centre College with a Bachelor of Science degree, and she received her Master’s and Doctoral degrees in Clinical Psychology from Xavier University. She completed her doctoral internship in Winnipeg, Canada at the University of Manitoba. Dr. Twehues has provided psychological services, including psychotherapy and psychological assessment, to children, adults, and the elderly. She has worked in a variety of settings, including private practice, mental health agencies, and college counseling centers. Dr. Twehues works part-time at SPS providing assessment services and has particular interest in worker’s compensation and guardianship evaluations. </p>
                            </div>    
                    ):(
                      <div className='member' style={styles.member} onClick={this.showMoreJessica}>
                      <img src={twehues} style={styles.memberImg} alt="Twehues" />
                      <h1 style={styles.name}>Jessica Twehues</h1>
                      <h2 style={styles.title}>Psy.D.</h2>
                  </div>
                    )
                }
            </div>
            </div>

            <div className='wholeMember'>
            
            <div className='bioContainer' style={styles.bioContainer}>
                {
                    this.state.showMoreJason
                    ?(
                        <div
                            className='more'
                            style={styles.bioContainer}
                            ref={(element) => {
                                this.dropdownMenu = element;
                            }}>
                                <h2 style={styles.bioHead}>About Jason Weisman</h2>
                <p style={styles.bioCopy}>Jason Weisman, Psy.D., C.C.T.P., completed his undergraduate education, with a major in psychology, at the University of Cincinnati, and earned a Master’s in clinical psychology at the University of Dayton. Dr. Weisman earned his Doctorate in clinical psychology at Wright State University School of Professional Psychology, with a specific focus on health psychology and assessment. Dr. Weisman trained at the Forensic Psychiatry Center for Western Ohio during his formal internship and two-year post-doctoral fellowship. He developed and lead the implementation of trauma-focused treatment for specialty court dockets in Montgomery County, and obtained a Certified Clinical Trauma Professional credential. His experience in assessment and evaluation led to responsibilities as Co-Director of the Assessment Center at Talbert House. In addition to providing consultation to the courts and probation, and program development, Dr. Weisman has conducted seminars, workshops, and didactics for lay public, police departments, clinical treatment providers, agency staff, and consumers on topics that include trauma-informed care, complex trauma, managing stress at work and home, ethics, major mental illnesses, mental health and addiction, intensive-level of care substance use disorder treatment, and professional development. He is a member of the Cincinnati Academy of Professional Psychology. </p>
                            </div>    
                    ):(
                      <div className='member' style={styles.member} onClick={this.showMoreJason}>
                      <img src={weisman} style={styles.memberImg} alt="Weisman" />
                      <h1 style={styles.name}>Jason Weisman</h1>
                      <h2 style={styles.title}>Psy.D., C.C.T.P.</h2>
                  </div>
                    )
                }
            </div>
            </div>

            <div className='wholeMember'>
            
            <div className='bioContainer' style={styles.bioContainer}>
                {
                    this.state.showMoreNicole
                    ?(
                        <div
                            className='more'
                            style={styles.bioContainer}
                            ref={(element) => {
                                this.dropdownMenu = element;
                            }}>
                                <h2 style={styles.bioHead}>About Nicole Westrich-Purdy</h2>
                <p style={styles.bioCopy}>Nicole Westrich-Purdy received her Bachelor’s degree in Media Communications, from Ohio University, in Athens, Ohio. There, she also received her Minor in Psychology, and a Certificate in Political Communications. With a background in medical device sales and account management, Nicole is excited to be a part of the SPS office management team. She is one of the two friendly voices you’ll hear at the other end of the telephone. She manages scheduling, appointments, client contracts, and all of the behind the scenes activities that keep our office running smoothly. </p>
                            </div>    
                    ):(
                      <div className='member' style={styles.member} onClick={this.showMoreNicole}>
                      <img src={westrich} style={styles.memberImg} alt="Nicole Westrich-Purdy" />
                      <h1 style={styles.name}>Nicole Westrich-Purdy</h1>
                      <h2 style={styles.title}>B.S.S.</h2>
                      </div>
                    )
                }
            </div>
            </div>

            <div className='wholeMember'>
            
            <div className='bioContainer' style={styles.bioContainer}>
                {
                    this.state.showMoreKristen
                    ?(
                        <div
                            className='more'
                            style={styles.bioContainer}
                            ref={(element) => {
                                this.dropdownMenu = element;
                            }}>
                                <h2 style={styles.bioHead}>About Kristen A. Wilson</h2>
                <p style={styles.bioCopy}>Kristen Wilson, Psy.D., completed her undergraduate education at Miami University.  Dr. Wilson then attended Xavier University and earned her Doctorate in Clinical Psychology.  Dr. Wilson has experience with individual therapy (i.e., children, adolescents, and adults), couple’s therapy, family therapy and psychological assessment.  Dr. Wilson has provided psychological services, in a variety of settings including schools, community mental health, correctional, inpatient and private practice.  Dr. Wilson works part-time at SPS providing assessment services and has particular interest in psychoeducational and psychological assessment.   </p>
                            </div>    
                    ):(
                      <div className='member' style={styles.member} onClick={this.showMoreKristen}>
                      <img src={wilson} style={styles.memberImg} alt="Wilson" />
                      <h1 style={styles.name}>Kristen A. Wilson</h1>
                      <h2 style={styles.title}>Psy.D.</h2>
                  </div>
                    )
                }
            </div>
            </div>

            <div className='wholeMember'>
            
            <div className='bioContainer' style={styles.bioContainer}>
                {
                    this.state.showMoreZeitchick
                    ?(
                        <div
                            className='more'
                            style={styles.bioContainer}
                            ref={(element) => {
                                this.dropdownMenu = element;
                            }}>
                                <h2 style={styles.bioHead}>About Alex L. Zeitchick</h2>
                <p style={styles.bioCopy}>Alex Zeitchick, Psy.D. is a forensic and clinical psychologist who has been licensed in North Carolina since 2023, and in South Carolina since 2019. Dr. Zeitchick earned his undergraduate degree from Connecticut College and his doctoral degree from Xavier University. In 2017, he completed an APA accredited internship at Southeast Human Service Center in Fargo, ND and the following year he finished his residency at the North Dakota State Hospital. Since becoming a licensed psychologist, Dr. Zeitchick has conducted numerous court-ordered forensic psychological evaluations as well as clinical and diagnostic assessments. He has consulted on many cases ranging in complexity and provided expert testimony when needed. The majority of his experience is through juvenile and family court, although he also frequently performs evaluations for adults in superior and district court. Dr. Zeitchick has diverse experience providing services to individuals across the age span in a variety of contexts, which includes outpatient practices, inpatient hospitals, residential programs, academic environments, and detention settings. His main interests are in child and adolescent psychopathology, specifically aggressive and violent individuals, and with issues related to hypermasculinity and trauma. Other areas of interest of his include threat and risk assessment in various contexts.   </p>
                            </div>    
                    ):(
                      <div className='member' style={styles.member} onClick={this.showMoreZeitchick}>
                      <img src={zeitchick} style={styles.memberImg} alt="Wilson" />
                      <h1 style={styles.name}>Alex L. Zeitchick</h1>
                      <h2 style={styles.title}>Psy.D.</h2>
                  </div>
                    )
                }
            </div>
            </div>

            <div className='wholeMember'>
            
            <div className='bioContainer' style={styles.bioContainer}>
                {
                    this.state.showMoreMarjorie
                    ?(
                        <div
                            className='more'
                            style={styles.bioContainer}
                            ref={(element) => {
                                this.dropdownMenu = element;
                            }}>
                                <h2 style={styles.bioHead}>About Marjorie A. Bagwell Kukor</h2>
                <p style={styles.bioCopy}>Marjorie Bagwell Kukor, Ph.D., earned her undergraduate degree from the University of Cincinnati, graduating Magna Cum Laude; she received her master’s degree in Experimental Psychology and her doctorate in Clinical Psychology at Miami University.  Dr. Kukor has worked in the field for over 30 years and has experience and expertise in a number of areas.    

Her areas of expertise include forensic assessments, competency restoration treatment, religious assessments (psychological evaluations, candidate assessments and re-entry workshops),  crisis response (including CISM), trauma response and recovery, disaster response and resiliency, suicide risk assessments, military culture and therapy, and women’s issues with an emphasis on pregnancy and post-partum concerns.  She provides trainings in suicide risk assessment (AMSR), military culture, de-escalation, and other topics as requested for various organizations.  She continues to be a subject matter expert and a volunteer for the American Red Cross.   She works part time for SPS providing assessments. 
</p>
                            </div>    
                    ):(
                      <div className='member' style={styles.member} onClick={this.showMoreMarjorie}>
                      <img src={blank} alt="Blank" style={styles.memberImg} />
                      <center><h1 style={styles.name}>Marjorie A. <br /> Bagwell Kukor</h1></center>
                      <h2 style={styles.title}>Ph.D.</h2>
                  </div>
                    )
                }
            </div>
            </div>

            <div className='wholeMember'>
            
            <div className='bioContainer' style={styles.bioContainer}>
                {
                    this.state.showMoreTawnee
                    ?(
                        <div
                            className='more'
                            style={styles.bioContainer}
                            ref={(element) => {
                                this.dropdownMenu = element;
                            }}>
                                <h2 style={styles.bioHead}>About Tawnee Tanner</h2>
                <p style={styles.bioCopy}>Dr. Tawnee Tanner received her Bachelor’s degree in Criminology and Psychology at Indiana University of Pennsylvania. She then earned her Master’s degree in Criminal Justice-Forensic Psychology at Tiffin University in Ohio, which she followed up by earning her Psy.D. in Clinical Forensic Psychology at The Chicago School of Professional Psychology in Illinois. Her experiences include providing therapy services in outpatient settings, psychological assessments, and mental health treatment in correctional settings. Currently, Dr. Tanner works part-time at Sparks Psychological Services providing assessment services.   </p>
                            </div>    
                    ):(
                      <div className='member' style={styles.member} onClick={this.showMoreTawnee}>
                      <img src={blank} style={styles.memberImg} alt="Blank" />
                      <h1 style={styles.name}>Tawnee Tanner</h1>
                      <h2 style={styles.title}>Psy.D.</h2>
                  </div>
                    )
                }
            </div>
            </div>
            
            </Slider>
        </div>
        </div>
      </div>
    )
}
}