export default {
    forensicContainer:{
        margin: '1.5em',
        height: '500px',
        backgroundColor: '#143257',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        padding: '1.5em 1em 1.5em 0',
        display: 'flex',
        borderRadius: '2em 0',
        
    },
    forensicContainerMobile:{
        margin: '1.5em',
        height: '700px',
        backgroundColor: '#143257',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        padding: '1.5em 0 1.5em 0',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '2em 0',
        
    },
    forensicCopy: {
        width: '45%',
        padding: '1em 0',
        
    },
    forensicCopyMobile: {
        padding: '1em 0',
       
    },
    itemContainer:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        
    },
    head:{
        padding: '.5em 0',
        margin: '.5em 0',
        color: '#555',
        fontSize: '2em',
        textTransform: 'uppercase',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#f6dd89',
       

    },
    line: {
        backgroundColor: 'white',
        height: '1px',
        //width: '300px',
        display: 'flex',
        alignItems: 'center',
        justifyContent:'center',
       

    },
    item: {
        padding: '1em',
        color: '#E6B277',
        fontWeight: '500',
        textTransform: 'capitalize',
        fontSize: '1.15em',
        display: 'flex',
        justifyContent:'center',
        
        //listStyleType: 'circle'
    },
    parallaxContainer:{
        width: '50vw', 
        height: '500px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        
        
    },
    forensicImage: {
        width: '100vw', 
        maxWidth: '950px',
        height: '500px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        
    },
    forensicImageMobile: {
        width: '100%', 
        //maxWidth: '950px',
        height: '500px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
       
    }
    /*
    forensicImage: {
        width: "100%",
        //height: "500px",
        backgroundImage: `url(${forensicImage})`,
        backgroundSize: 'cover',
        backgroundPosition: ' center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
    */
    

}